import moment from "moment";
import { toast } from "react-toastify";
import { DATE_FORMAT } from "apis/localdb";

export type Item = {
    value?: string | number;
    label?: string | number;
    imageUrl?: string;
    [x: string]: any;
};

export const convertObToUrl = (obj: any) => {
    let str = "";
    for (const key in obj) {
        if (str != "") {
            str += "&";
        }
        str += key + "=" + encodeURIComponent(obj[key]);
    };
    return str;
};

/**
 * Converts an object to a query string.
 * @param obj - The object to convert.
 * @returns The query string.
 */
export const convertObjectToQueryString = (obj: Record<string, any>): string => {
  const queryString = Object.keys(obj)
    .map(key => {
      if (Array.isArray(obj[key])) {
        return obj[key].map((val: any) => `${encodeURIComponent(key)}[]=${encodeURIComponent(val)}`).join('&');
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
    })
    .join('&');
  return queryString;
};

export const convertDateToString = (date: any) => {
    return (date && moment(date).isValid() && moment(date).toJSON()) || ""
}

export const convertDisplayDate = (date: any, format = DATE_FORMAT) => {
    return moment(date).isValid() && moment(date).format(format)
}

export const formatNumberMoney = (number: any) => {
    return (Number(number).toLocaleString('de-DE')) || ""
}

export const convertInitialValueDropdown = (item: any, value = '_id', label = 'name', label_2 = ''): Item | '' => {
    if (!item) {
        return ''
    }

    if (item.isInitialValue) {
        return item
    }

    return {
        value: item[value],
        label: label_2 ? item[label] + " - " + item[label_2] : item[label],
        isInitialValue: true
    }
}

export const nl2p = function (text: string){
    if(!text) return '';

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const urlHasHrefRegex = /(href="https?:\/\/[^\s]+)/g;
    const arr = text?.split("\n") ?? [];
    let paragraphs = '';

    for (const item of arr) {
        if(urlHasHrefRegex.test(item)) {
            paragraphs = paragraphs + '<p>' + item + '</p>';
        } else{
            paragraphs = paragraphs + '<p>' + item.replace(urlRegex, '<a style="font-weight: normal;" href="$1" target="_blank" rel="noopener noreferrer">$1</a>') + '</p>';
        }
    }

    return paragraphs;
}

export const removeHTMLTags = (text: string) => {
    return text ? text.replace(/<[^>]+>/gi, '') : '';
}

export const sliceText = (text: string, from: number, to: number, isHaveDot = true) => {
    if(!text) return '';
    return !isHaveDot ? text.slice(from, to) : (text.length > to ? `${text.slice(from, to)}...` : text.slice(from, to))
}

export async function handleException (callback: any, callbackError?: any){
    try {
        await callback();
    } catch (error) {
        if(callbackError) return callbackError(error);

        if (typeof error === "string") {
            toast.error(error);
        } else if (error instanceof Error) {
            toast.error(error.message);
        }
    }
}

export function createLinkAndClick(link: string, target = '_blank') {
    const a = document.createElement('a');
    a.href = link;
    a.target = target;
    a.click();
    a.remove();
}

export function generateIamgePath(path: string): string {
  return `${process.env.REACT_APP_URL_S3}${path}`;
}